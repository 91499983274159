"use client";

import {
  createContext,
  FC,
  ReactNode,
  useEffect,
} from "react";
import { useCookies } from "react-cookie";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { usePathname } from "next/navigation";

import { scrubSensitiveData } from "@meow/core";

// TODO:
// 1. Admin portal cookie with parsing
// 2. Move _dd_s datadog session id logic to use datadog RUM

type DatadogLoggerInstance = typeof datadogLogs;

export const datadogContext = createContext<DatadogLoggerInstance>(
  undefined as unknown as DatadogLoggerInstance,
);

const COOKIES_TO_RECORD = [
  "kk_starter_box",
  "kk_email",
  "kk_discount_code",
  "_kk_referrer_page",
  "kk_customer_id",
  "kk_session_referrer",
  "kk_referrers",
  "kk_anonymous_id",
  "shopifyId",
  // TODO: Admin portal cookie
];

// These are local storage variables where we will store forms-related data that will be used to enrich logs
export const FORM_LAST_USER_ID = "form_last_user_id";
export const FORM_LAST_EMAIL = "form_last_email";
export const FORM_LAST_OLD_USER_ID = "form_last_old_user_id";
const LOCAL_STORAGE_TO_RECORD = [
  FORM_LAST_USER_ID,
  FORM_LAST_EMAIL,
  FORM_LAST_OLD_USER_ID,
];

interface WithChildrenAndConfig {
  children?: ReactNode | ReactNode[];
  anikinEnvironment: string,
  ddRumClientToken: string,
  ddRumAppId: string,
  appName: string,
}

// Even though we're using a static instance of the datadog logger here,
// passing it down as a context to be callable from a hook keeps things
// consistent
export const DatadogProvider: FC<WithChildrenAndConfig> = ({ children, anikinEnvironment,
  ddRumClientToken,
  ddRumAppId,
  appName }) => {
  const [ cookies ] = useCookies();

  const pathName = usePathname();

  useEffect(() => {

    // TODO: put token in secrets, and handle env switch
    datadogLogs.init({
      clientToken: "pub7fdec2a3783c420152630750a7be5949",
      site: "datadoghq.eu",
      service: appName,
      env: anikinEnvironment,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      silentMultipleInit: false,
      trackSessionAcrossSubdomains: true,
      beforeSend: (event) => {
        scrubSensitiveData(event);
        return true;
      },
    });
    datadogRum.init({
      applicationId: ddRumAppId,
      clientToken: ddRumClientToken,
      site: "datadoghq.eu",
      service: appName,
      env: anikinEnvironment,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 5,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      trackSessionAcrossSubdomains: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [
        /^https?:\/\/localhost(:[0-9]+)?(\/.*)?$/,
        /^https?:\/\/([a-zA-Z-.]+\.)?katkin.com(\/.*)?$/,
        /^https?:\/\/([a-zA-Z-.]+\.)?katkin.club(\/.*)?$/,
        /^https?:\/\/([a-zA-Z-.]+\.)?ankn.io(\/.*)?$/,
      ],
      enableExperimentalFeatures: [ "clickmap" ],
    });

    // This ensures that datadog logs also appear in the console
    datadogLogs.logger.setHandler([ "http", "console" ]);

    datadogLogs.addLoggerGlobalContext("eventName", "BrowserDDDefaultEvent");
    datadogLogs.addLoggerGlobalContext("eventType", "BrowserDDDefaultType");
    if (document.referrer) {
      datadogLogs.addLoggerGlobalContext("dd_referrer", document.referrer);
    }
    datadogLogs.addLoggerGlobalContext("dd_loadDate", new Date().toString());

    for (const cookieName of COOKIES_TO_RECORD) {
      const value = cookies[cookieName];
      if (value) datadogLogs.addLoggerGlobalContext(cookieName, value);
    }
    for (const lsName of LOCAL_STORAGE_TO_RECORD) {
      const value = localStorage.getItem(lsName);
      if (value) datadogLogs.addLoggerGlobalContext(lsName, value);
    }

  }, []);

  useEffect(() => {
    function logRouteChange() {
      datadogLogs.logger.log("navigated", { eventName: "BrowserNavigated" }, "info");
    }
    logRouteChange();
    datadogRum.startSessionReplayRecording();

    return () => {
      datadogRum.stopSessionReplayRecording();
    };
  }, [ pathName ]);

  return (
    <datadogContext.Provider value={datadogLogs}>
      { children }
    </datadogContext.Provider>
  );
};
