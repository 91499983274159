/* eslint-disable no-console */
import type { LoggerService } from "@nestjs/common";
import dayjs from "dayjs";

import {
  LogLevelWithVerbose,
  PerServiceDisabledLogLevels,
  undefinedIfEmptySelfIfSingle,
} from "./utils";
import { scrubSensitiveData } from "./scrubSensitiveData";

export class Meow implements LoggerService {

  constructor(
    private context = "NoContext",
    private appName = "NoApp",
    private readonly devStyleLogs: boolean = false,
    private readonly disabledLogLevels: ReadonlySet<string> = new Set(),
    private readonly perServiceDisabledLogLevels: PerServiceDisabledLogLevels = new Map(),
  ) {}

  public setAppName(appName: string): void {
    this.appName = appName;
  }

  public setContext(context: string): void {
    this.context = context;
  }

  public log(message: string, ...optionalParams: unknown[]) {
    this.emitLog(message, "log", optionalParams);
  }

  public info(message: string, ...optionalParams: unknown[]) {
    this.emitLog(message, "info", optionalParams);
  }

  public error(message: string, ...optionalParams: unknown[]) {
    this.emitLog(message, "error", optionalParams);
  }

  public warn(message: string, ...optionalParams: unknown[]) {
    this.emitLog(message, "warn", optionalParams);
  }

  public debug(message: string, ...optionalParams: unknown[]) {
    this.emitLog(message, "debug", optionalParams);
  }

  public verbose(message: string, ...optionalParams: unknown[]) {
    this.emitLog(message, "verbose", optionalParams);
  }

  public trace(message: string, ...optionalParams: unknown[]) {
    this.emitLog(message, "trace", optionalParams);
  }

  private emitLog(message: string, level: LogLevelWithVerbose, optionalParams: unknown[]) {
    try {
      const { params, context, error } = this.getParamsAndContext(optionalParams);
      if (this.logLevelDisabled(context, level)) return;
      if (this.devStyleLogs) {
        this.emitLogDev(message, level, params, context, error);
      } else {
        this.emitLogProd(message, level, params, context, error);
      }
    } catch (e) {
      console.error("log failed", e);
      console.log({ message, level, optionalParams });
      console.debug(optionalParams);
    }
  }

  private emitLogDev(
    message: string,
    level: LogLevelWithVerbose,
    optionalParams: unknown[] | unknown | undefined,
    context: string,
    error: IDDError | undefined,
  ) {
    const logColour = Meow.getLogColour(level);
    this.getLogFunction(level)(
      Meow.addColourToString(
        dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        ConsoleColour.Blue,
      ),
      Meow.addColourToString(level.toUpperCase(), logColour),
      `[${Meow.addColourToString(this.appName, ConsoleColour.Magenta)}]`,
      `[${Meow.addColourToString(context, ConsoleColour.Yellow)}]`,
      Meow.addColourToString(message, logColour),
      optionalParams ?? "",
      error?.stack ? Meow.addColourToString(error.stack, ConsoleColour.Red) : "",
    );
  }

  private emitLogProd(
    message: string,
    level: LogLevelWithVerbose,
    optionalParams: unknown[] | unknown | undefined,
    context: string,
    error: IDDError | undefined,
  ) {
    // Log to a file? that can be picked up by DD
    this.getLogFunction(level)(JSON.stringify(scrubSensitiveData({
      timestamp: (dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ")),
      level: (level.toUpperCase()),
      appName: this.appName,
      context,
      optionalParams,
      error,
      // This is a workaround because datadog erases message if error.message is set
      message: error?.message ? undefined : message,
      eventMessage: error?.message ? message : undefined,
    })));
  }

  private getLogFunction(level: LogLevelWithVerbose) {
    switch (level) {
      case "error":
        return console.error;
      case "warn":
        return console.warn;
      case "info":
        return console.info;
      case "log":
        return console.log;
      case "debug":
      case "verbose":
        return console.debug;
      case "trace":
        return console.trace;
      default: {
        const unknown: unknown = level;
        console.error("getLogFunction: Unexpected log level", { unknown });
        throw Error(`Unexpected log level ${unknown}`);
      }
    }
  }

  private static addColourToString(message: string, colour: ConsoleColour): string {
    return colour + message + ConsoleColour.Black;
  }

  private static getLogColour(level: LogLevelWithVerbose): ConsoleColour {
    switch (level) {
      case "error":
        return ConsoleColour.Red;
      case "warn":
        return ConsoleColour.Yellow;
      case "info":
      case "log":
        return ConsoleColour.Green;
      case "debug":
      case "trace":
      case "verbose":
        return ConsoleColour.Grey;
      default:
        return ConsoleColour.Black;
    }
  }

  private getParamsAndContext<T>(optionalParams: T[]): {
    params: T[] | T | undefined;
    context: string;
    error: IDDError | undefined;
  } {
    const lastParam = optionalParams.at(-1);
    if (typeof lastParam === "string" && lastParam.trim()) {
      const params = undefinedIfEmptySelfIfSingle(optionalParams.slice(0, -1));
      return {
        params,
        context: lastParam,
        error: getErrorIfExists(params),
      };
    }
    const params = undefinedIfEmptySelfIfSingle(optionalParams);
    return {
      params,
      context: this.context,
      error: getErrorIfExists(params),
    };

  }

  private logLevelDisabled(context: string, level: LogLevelWithVerbose) {
    const restrictions = this.perServiceDisabledLogLevels.get(this.appName)?.get(context)
      || this.perServiceDisabledLogLevels.get(this.appName)?.get("ALL")
      || this.perServiceDisabledLogLevels.get("ALL")?.get(context)
      || this.perServiceDisabledLogLevels.get("ALL")?.get("ALL")
      || this.disabledLogLevels;
    return restrictions.has(level);
  }

}

// ANSI escape sequence colours with `\x1b` being the escape character and [ being the starting character
// For more info, see https://en.wikipedia.org/wiki/ANSI_escape_code#Colors
enum ConsoleColour {
  Red = "\x1b[31m",
  Yellow = "\x1b[33m",
  Green = "\x1b[32m",
  Grey = "\x1b[38m",
  Black = "\x1b[0m",
  Blue = "\x1b[34m",
  Magenta = "\x1b[35m",
}

interface IDDError {
  stack: string | undefined;
  message: string | undefined;
  errorMessage: string | undefined;
  kind: string | undefined;
  cause: IDDError & { stack: undefined } | undefined;
}

function getErrorIfExists<T>(params: T | T[] | undefined): IDDError | undefined {
  if (params === undefined || !params) return undefined;
  if (!Array.isArray(params)) {
    const earlyErrorCheck = errorOrUndefined(params);
    if (earlyErrorCheck) return earlyErrorCheck;
    if (typeof params === "object") {
      const vals = Object.values(params);
      for (const val of vals) {
        const error = errorOrUndefined(val);
        if (error) return error;
      }
    }
    return undefined;
  }
  for (const param of params) {
    const error = errorOrUndefined(param);
    if (error) return error;
  }
  return undefined;
}

function errorOrUndefined(toCheck: unknown): IDDError | undefined {
  if (!toCheck || typeof toCheck !== "object") return undefined;
  if ("name" in toCheck
    && typeof toCheck.name === "string"
    && "message" in toCheck
    && typeof toCheck.message === "string"
    && (!("stack" in toCheck)
      || typeof toCheck.stack === "string"
      || toCheck.stack === undefined
      || toCheck.stack === null)) {
    const stack = "stack" in toCheck ? toCheck.stack : undefined;
    const cause = "cause" in toCheck ? errorOrUndefined(toCheck.cause) : undefined;
    return {
      kind: toCheck.name,
      message: toCheck.message,
      // This is a workaround because datadog erases error.message from the log
      errorMessage: toCheck.message,
      stack: mergeStack(toCheck.name, toCheck.message, stack, cause?.stack),
      cause: cause ? {
        ...cause,
        stack: undefined,
      } : undefined,
    };
  }
  return undefined;
}

function mergeStack(
  kind: string | undefined,
  message: string | undefined,
  stack: unknown,
  causeStack: string | undefined,
) {
  if (causeStack) {
    return `${
      typeof stack === "string"
        ? stack
        : `${kind || "Unknown Error"}: ${message || "No message"}\n    No stack`
    }\n  Caused by: ${causeStack}`;
  }
  return typeof stack === "string" ? stack : undefined;
}
