// All of these path suffixes will be scrubbed unconditionally
// If you define a path like this { pathToScrub: [ "A", "B" ] },
// then B will be scrubbed in this case { "C": { "A": { "B": "scrubbed" } } }
// but not in this case { "A": { "C": { "B": "not-scrubbed" } } }
// nor this case { "B": "not-scrubbed" }
const PATHS_TO_SCRUB: ReadonlyArray<{ pathToScrub: readonly [string, ...string[]] }> = [
  { pathToScrub: [ "setupIntentClientSecret" ] },
];
// If a string matches this regex, it will be anonymised through the given function
// If multiple regexes match, then the first one will be used
const REGEX_TO_CHECK: ReadonlyArray<{ regex: RegExp, replacement: (toReplace: string) => string }> = [
  {
    regex: /seti_[^_]+_secret_/,
    replacement: (toReplace: string) => toReplace.replace(/(seti_[^_]+_secret_)[a-zA-Z0-9]+/, "$1SCRUBBED"),
  },
];

/** Will replace sensitive data in the object
 * @param objectToScrub the object to check
 * @param returnCopy if set to true, scrubbing will be made on a copy of the scrubbable value
 * @param seen all objects seen so far, to prevent loops
 * @param path path followed so far */
export function scrubSensitiveData(
  objectToScrub: object,
  returnCopy = false,
  seen: { item: object, replacement: object }[] = [],
  path: (string | number)[] = [],
): object | undefined {
  // First, we check to see if we've already encountered this object
  const replacementAlreadyExists = seen.find(({ item }) => item === objectToScrub);
  // If we have, we return the already calculated object (handles self-reference/infinite-recursion)
  if (replacementAlreadyExists) {
    return replacementAlreadyExists.replacement;
  }
  // This will change from undefined when we create a copy of the object
  let scrubData: { copy: object, newSeen: { item: object, replacement: object }[] } | undefined;
  function getScrubData(): { copy: object, newSeen: { item: object, replacement: object }[] } {
    if (scrubData === undefined) {
      const copy = returnCopy
        ? Array.isArray(objectToScrub)
          ? [ ...objectToScrub ]
          : { ...objectToScrub }
        : objectToScrub;
      const newSeen = [ ...seen, { item: objectToScrub, replacement: copy } ];
      scrubData = { copy, newSeen };
    }
    return scrubData;
  }
  // This will change to true if we scrub anything in this object or its parent objects
  let scrubbed = false;
  // For every item in the object
  (Array.isArray(objectToScrub)
    ? objectToScrub.map((value, index) => [ index, value ])
    : Object.entries(objectToScrub)).forEach(([ key, itemToCheck ]) => {
    const type = typeof itemToCheck;
    switch (type) {
      case "object": {
        const toCheck: NonNullable<unknown> | null = itemToCheck as object;
        // No need to scrub null objects
        if (toCheck === null) {
          break;
        }
        const iScrubData = getScrubData();
        const replacement = scrubSensitiveData(toCheck, true, iScrubData.newSeen, [ ...path, key ]);
        // If we ended up scrubbing recursively, replace the object with the object copy
        if (replacement !== toCheck) {
          // @ts-ignore
          iScrubData.copy[key] = replacement;
          scrubbed = true;
        }
        break;
      }
      case "undefined":
      case "boolean":
      case "symbol":
      case "function":
        // I assume we'll never want to scrub one of these
        break;
      case "string": {
        const stringToCheck: string = itemToCheck;
        const replacementRegex = REGEX_TO_CHECK.find(({ regex }) => stringToCheck.match(regex));
        if (replacementRegex) {
          // @ts-ignore
          getScrubData().copy[key] = replacementRegex.replacement(itemToCheck);
          scrubbed = true;
          break;
        }
        if (pathMatches([ ...path, key ])) {
          // @ts-ignore
          getScrubData().copy[key] = scrub(itemToCheck);
          scrubbed = true;
        }
        break;
      }
      case "number":
      case "bigint":
        if (pathMatches([ ...path, key ])) {
          // @ts-ignore
          getScrubData().copy[key] = scrub(itemToCheck);
          scrubbed = true;
        }
        break;
      default: {
        // If we don't know the type, do nothing
        const never: never = type;
        // eslint-disable-next-line no-console
        console.warn(`Scrubber encountered unknown type ${never}`);
        break;
      }
    }
  });
  if (scrubbed) return getScrubData().copy;
  // If we didn't change anything, there's no point in returning the copy
  return objectToScrub;
}

/** @return true if the current path suffix matches one of the paths to scrub */
function pathMatches(currPath: string[]): boolean {
  return PATHS_TO_SCRUB.some(({ pathToScrub }) => pathToScrub.length <= currPath.length
    && pathToScrub.every((pathSegment, index) => pathSegment === currPath[
      currPath.length - pathToScrub.length + index
    ]));
}

/** @return The string SCRUBBED#L where L is the length of the scrubbed value when transformed into a string. */
function scrub(toScrub: number | bigint | string): string {
  return `SCRUBBED#${toScrub.toString().length}`;
}
